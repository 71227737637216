.modal {
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: calc(100dvh + 400px);
    background: rgba(37, 0, 0, 0.8);
    z-index: 1000;
}

.cellAvatar {
    position: absolute;
    left: 10px;
    top: 10px;
}

.cellAvatarManaSymbols {
    position: absolute;
    left: 5px;
    top: 75px;
    width: 60px;
    text-align: center;
}

.cellAvatarManaSymbolsContainer {
    background-color: #f7d804;
    align-self: center;
}

.TextCell {
    white-space: pre-line;
    word-wrap: normal;
    font-size: 1rem;
    vertical-align: middle;
    /* height: 100%; */
    min-height: 50px;
    /* margin-top: 50%; */
    /* font-weight: bold; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/*!* If the screen size is 601px wide or more, set the font-size of <div> to 80px *!*/
/*@media screen and (min-width: 601px) {*/
/*    div.example {*/
/*        font-size: 80px;*/
/*    }*/
/*}*/


.cellTitleLabel {
    /*white-space: nowrap;*/
    overflow: hidden;
    font-size: 1rem;
    vertical-align: middle;
    color: #fcef95;
    max-height: 45px;
    text-overflow: ellipsis;
    /* height: 100%; */
    /*min-height: 50px;*/
    /* margin-top: 50%; */
     font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.cellCommanderLabel {
    white-space: pre-line;
    overflow: hidden;
    font-size: 1.2rem;
    vertical-align: middle;
    max-height: 50px;
    text-overflow: ellipsis;
    /* height: 100%; */
    /*min-height: 30px;*/
    /* margin-top: 50%; */
    /* font-weight: bold; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .cellTitleLabel {
        /*white-space: nowrap;*/
        overflow: hidden;
        font-size: .7rem;
        vertical-align: middle;
        color: #fcef95;
        max-height: 45px;
        text-overflow: ellipsis;
        /* height: 100%; */
        /*min-height: 50px;*/
        /* margin-top: 50%; */
        font-weight: bold;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .cellCommanderLabel {
        white-space: pre-line;
        overflow: hidden;
        font-size: .9rem;
        vertical-align: middle;
        max-height: 50px;
        text-overflow: ellipsis;
        /* height: 100%; */
        /*min-height: 30px;*/
        /* margin-top: 50%; */
        /* font-weight: bold; */
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
}

.scoreHeaderCell {
    position: fixed;
    top: 5px;
    overflow: visible;
}

.sortLabel {
    padding-left: 0px;
    font-size: 11px;
    font-weight: bolder;
    margin-top: 2px;
    white-space: nowrap;
    text-overflow: unset;
    /* margin-left:10px; */
}

.cellScore {
    font-size: 20px;
    font-weight: bolder;
    color:crimson;
    float: right;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
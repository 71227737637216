.synergyMenuBox {
    max-width: 600px;
}

.synergyMenuBoxLabel {
    width: 75px;
    text-align: right;
    padding-right: 10px;
    font-size: .9em;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.synergyMenuBoxActionLabel {
    /*width: 300px;*/
    /*max-width: 600px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .9em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

. {
    font-size: 1em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /*padding: 10px;*/
    /*display: block;*/
    /*min-width: 200px;*/
    /*min-height: 50px;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    line-height: var(--spectrum-global-font-line-height-small, 1.3);
    word-break: break-word;
    grid-template-columns: 400px;
}

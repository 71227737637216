.upgradeMenuBox {
    width: 100%;
    max-width: 600px;
}

.upgradeMenuBoxLabel {
    width: 75px;
    text-align: right;
    padding-right: 10px;
    font-size: .9em;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.upgradeMenuBoxActionLabel {
    /*display: initial;*/
    /*width: 350px;*/
    /*width: 100%;*/
    font-size: .9em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.upgradeMenuBoxItem {
    font-size: 1em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

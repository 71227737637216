.commanderNamesTextContainer {
    background: linear-gradient(to right, rgb(72, 83, 102), 30%, transparent);
    background-repeat: no-repeat;
    background-position: right;
    color: white;
    padding: 20px 0px 20px 20px;
    margin-bottom: 20px;
}

.rightPanel {
    /*float: right;*/
    display: inline-block;
    min-width: clamp(400px, 100%, 500px);
    /*display: contents;*/
    vertical-align: top;
    max-width: 500px;
}

.leftPanel {
    /*float: left;*/
    display: inline-block;
    min-width: clamp(250px, 100%, 250px);
    /*vertical-align: top;*/
    /*margin-right: 20px;*/
    margin-bottom: 20px;
}

.leftPanelPartner {
    /*float: left;*/
    display: inline-block;
    min-width: clamp(250px, 100vw, 500px);
    /*margin-right: 20px;*/
    margin-bottom: 20px;
}

.commandersMetaBreakdownHeader {
    font-size: .9rem;
    font-weight: bolder;
    text-align: center;
    background-color: rgb(66, 106, 201);
    text-shadow: 2px 2px 3px #000000;
    min-width: 300px;
    margin-bottom: 20px;
}

.commanderNamesText  {
    font-size: 18px;
    white-space: pre;
    font-weight: bold;
}

.metaFieldLabel {
    font-size: 20px;
    font-weight: bolder;
    color:white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.metaFieldValue {
    font-size: 20px;
    font-weight: bolder;
    color:crimson;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.commandersDetailsContainer {
    /*margin: 10px;*/
    width: 100%;
    /*padding: 50px;*/
    /*margin: 10px;*/
    /*display: flex;*/
    /*min-width: 600px;*/

    /*min-width: 600px;*/
    /*max-width: 1100px;*/
}

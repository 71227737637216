.previewGridContainer {
    display: inline-grid;
    row-gap: 0px;
    margin: 0px;
    background-color: black;
    border-bottom-style: solid;
    border-color: #2f2f2f;
    height: 100%;
}

.previewChildSectionContainer {

}

.previewExtraContentContainer {
    margin: 5px;
    border-style: none;
    background-color: #1d1d1d;
    font-size: .9rem;
}

.previewDetailSectionHeader {
    border-style: none;
    /*background-color: #1d1d1d;*/
    padding-right: 5px;
    padding-bottom: 5px;
    font-weight: bolder;
    font-size: .8rem;
}

.previewGridCell {
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-color: #2f2f2f;
    padding-top: 3px;
    padding-bottom: 3px;
    /*padding-left: 10px;*/
    font-size: 13px;
    display:table;
    width: 100%;
    /*flex-direction: column;*/
    /*height: max-content;*/
    /*white-space: pre-wrap;*/
}

.previewScoreBlock {
    font-size: 13px;
    padding-right: 10px;
    /*display: inline-block;*/
}

span.__react_modal_image__icon_menu {
    display: none;
}

.itemWithBonus {
    background: linear-gradient(to right, #236d6d, 30%, black);
    /*width: 100%;*/
}

.cardDetailsLink {
    margin-top:15px;
    font-size: 11px;
    cursor: pointer;
    color: #fad104;
    font-weight: bolder;
}

.previewGridCellContentLeft {
    /*background-color: #e8c600;*/
    min-width: 200px;
    /*display: inline;*/
    white-space: pre-wrap;
    display: inline;
    padding-left: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 14px;
    /*white-space: nowrap;*/
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
}

.previewGridCellContentLeftClickable {
    /*background-color: #e8c600;*/
    min-width: 200px;
    /*display: inline;*/
    white-space: pre-wrap;
    display: inline;
    padding-left: 10px;
    cursor:pointer;
    color: #e0f2fd;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 14px;


    /*white-space: nowrap;*/
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
}

.previewGridCellContentLeftClickable:hover {
    text-decoration-line: underline;
}

.previewGridCellContentBacksideLeft {
    /*background-color: #e8c600;*/
    min-width: 200px;
    /*display: inline;*/
    white-space: pre-wrap;
    display: inline;
    padding-left: 10px;
    color: #fff49a;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 14px;
    /*white-space: nowrap;*/
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
}

.previewGridCellContentBacksideLeftClickable {
    /*background-color: #e8c600;*/
    min-width: 200px;
    /*display: inline;*/
    white-space: pre-wrap;
    display: inline;
    padding-left: 10px;
    cursor:pointer;
    color: #fff49a;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 14px;


    /*white-space: nowrap;*/
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
}

.previewGridCellContentBacksideLeftClickable:hover {
    text-decoration-line: underline;
}

.previewGridCellHeaderRight {
    /*background-color: #e8c600;*/
    float: right;
    /*display: inline;*/
    white-space: pre-wrap;
    display: inline-block;
    /*white-space: nowrap;*/
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
}

.previewGridCellContentRight {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: bold;
    max-width: 200px;
    padding-right: 15px;
    font-size: 13px;
    float: right;
}

.previewGridCellContentRightBonus {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 13px;
    font-weight: bolder;
    color: #FF0000;
    max-width: 200px;
    padding-right: 15px;
    float: right;
}

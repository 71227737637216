.manabaseDetailsLabel {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 18px;
    color: rgb(255, 223, 97);
}

.manabaseDetailsName {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

.manabaseDetailsValue {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    font-weight: bolder;
    color: #69d7f8;
}

.manabaseDetailsSubDetailName {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

.manabaseDetailsSubDetailValue {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    font-weight: bolder;
    color: #69d7f8;
}


.manabaseStatisticsPanel {
    width: 330px;
    background-color: #262626;
}

.manabaseStatisticsPanelContent {
    margin: 10px;
}

.manabaseStatisticsHeader {
    white-space: nowrap;
    text-overflow: unset;
    overflow: clip;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 16px;
    background-color: #4f3c3c;
    width: 320px;
    height: 35px;
    padding-left: 10px;
    padding-top: 10px;
}

.manabaseStatisticsSubHeader {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    width: 150px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 14px;
}

.manabaseStatisticsDetailName {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 12px;
}

.manabaseStatisticsDetailValue {
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 12px;
    font-weight: bolder;
    color: #69d7f8;
}

.manabaseStatisticsPercentagesHeader {
    margin-top: 10px;
    white-space: nowrap;
    text-overflow: unset;
    display: inline-block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bolder;
    font-size: 14px;
}



.manabaseHeaderLabel {
    padding-right: 10px;
    font-size: .9em;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.manabaseMenuBoxLabel {
    /*text-align: right;*/
    font-size: .9em;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.manabaseMenuBoxActionLabel {
    /*display: initial;*/
    /*width: 350px;*/
    /*width: 100%;*/
    max-width: 300px;
    font-size: .9em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.manabaseMenuBoxItem {
    font-size: 1em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.filters-header {
  padding-left: 0px;
  font-size: .8em;
  font-weight: bolder;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: unset;
  /* margin-left:10px; */
}

.stickyBreadcrumbBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
  /*background-color: #1e1e1e;*/
  width: 100%;
  z-index: 1000;
}

.stickyBreadcrumbBarBottom {
  /*background: linear-gradient(to bottom, rgba(0,0,0,1), 30%, rgba(20,20,20,.1));*/
  background: linear-gradient(to bottom, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
  /*background-clip: text;*/
  color: transparent;
  height: 25px;
}

.filtersSectionLabel {
  padding-left: 0px;
  font-size: 11px;
  font-weight: bolder;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: unset;
  /* margin-left:10px; */
}

.filterSectionSpan {
  /*width: 500px;*/
  min-height: 75px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: clamp(300px, 100%, 100%);
  /*border-style: solid;*/
  /*border-width: .1em;*/
  /*border-color: #4b4b4b;*/
  margin-bottom: 20px;
}

.filterSectionSpanSmall {
  /*width: 500px;*/
  padding-left: 10px;
  padding-right: 10px;
  min-width: clamp(200px, calc(100vw - .5em), 250px);
  /*border-style: solid;*/
  /*border-width: .1em;*/
  /*border-color: #4b4b4b;*/
}

.filters-sources-header {
  /*box-sizing: border-box;*/
  /*padding: var(--spectrum-fieldlabel-padding-top,var(--spectrum-global-dimension-size-50))0 var(--spectrum-fieldlabel-padding-bottom,var(--spectrum-global-dimension-size-65));*/
  /*font-size: var(--spectrum-fieldlabel-text-size,var(--spectrum-global-dimension-font-size-75));*/
  /*font-weight: var(--spectrum-fieldlabel-text-font-weight,var(--spectrum-global-font-weight-regular));*/
  /*line-height: var(--spectrum-fieldlabel-text-line-height,var(--spectrum-global-font-line-height-small));*/
  /*vertical-align: top;*/
  /*-webkit-font-smoothing: subpixel-antialiased;*/
  /*-moz-osx-font-smoothing: auto;*/
  /*font-smoothing: subpixel-antialiased;*/
  /*cursor: default;*/
  /*flex: none;*/
  /*display: flex;*/
  /*color: var(--spectrum-fieldlabel-text-color,var(--spectrum-alias-label-text-color));*/
  font-size: .8rem;
}
.footerText {
  padding-left: 20px;
  font-size: 11px; 
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 20px;
  white-space: nowrap;
  text-overflow: unset;
}

.footerDiscordLink {
  padding-left: 15px;
  font-size: 11px;
  font-weight: bolder;
  height: 30px;
  /*margin-right: 20px;*/
  color: #f64e4e;
  white-space: nowrap;
  text-overflow: unset;
}
.modal {
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: calc(100dvh + 400px);
    background: rgba(37, 0, 0, 0.8);
    z-index: 1000;
}

.AvatarCell {
    position: absolute;
    left: 5px;
    top: 3px;
}

.TextCell {
    white-space: pre-line;
    word-wrap: normal;
    font-size: 17px;
    vertical-align: middle;
    /* height: 100%; */
    min-height: 50px;
    /* margin-top: 50%; */
    /* font-weight: bold; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.newFeatureLabel {
    margin-top:15px;
    color: #fad104;
    font-weight: bolder;
}


.newFeatureLabel:hover {
    cursor: pointer;
    text-decoration-line: underline;
}

.scoreHeaderCell {
    position: fixed;
    top: 5px;
    overflow: visible;
}

.sortLabel {
    padding-left: 0px;
    font-size: 11px;
    font-weight: bolder;
    margin-top: 2px;
    white-space: nowrap;
    text-overflow: unset;
    /* margin-left:10px; */
}

.SaltCell {
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
    color:crimson;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.SaltCellSubData {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: #808080;
    font-family: 'Gill Sans';
    white-space: nowrap;
    text-overflow: unset;
}

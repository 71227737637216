.categoriesSection {
    margin: 10px 15px 20px 0px;
    border-width: thin;
    border-style: solid;
    border-color: #303030;
    border-radius: 5px;
    /*background-color: #0dab25;*/
    width: 100%;
}

.categoriesLabel {
    /*min-width: 125px;*/
    /*margin: 2px;*/
    padding: 10px 10px 10px 10px;
    border-radius: 5px 5px 0px 0px;
    /*margin-right: 50px;*/
    font-size: 12px;
    color: #FFFFFF;
    background-color: #282828;
    font-weight: bolder;
    /*display: block;*/
    /*cursor: pointer;*/
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
    /*font-variant: common-ligatures small-caps;*/
}

.categoriesLabelUsedInPowerLevels {
    /*min-width: 125px;*/
    /*margin: 2px;*/
    padding: 10px 10px 10px 10px;
    border-radius: 5px 5px 0px 0px;
    /*margin-right: 50px;*/
    font-size: 12px;
    color: #ff856a;
    background-color: #282828;
    font-weight: bolder;
    /*display: block;*/
    /*cursor: pointer;*/
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
    /*font-variant: common-ligatures small-caps;*/
}

.indent {
    margin-left: 30px;
}

.propertySection {
    margin-bottom: 20px;
}

.disclaimerText {
    margin-top:15px;
    font-size: 11px;
    color: #fad104;
    font-weight: bolder;
}

.doubleIndent {
    margin-left: 60px;
}

.categoriesPropertyLabel {
    font-size: 10px;
    font-weight: bolder;
    padding-right: 5px;
    color: #FFFFFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}

.categoriesValueLabel {
    font-size: 11px;
    font-weight: bold;
    padding-right: 5px;
    color: #FFFFFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-variant-caps: small-caps;
}
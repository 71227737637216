.powerLevelText {
    font-size: .9em;
}

.powerLevelHeaderText {
    font-size: 1em;
    margin-bottom: 30px;
}

.powerLevelTextPrefix {
    font-size: 1.2em;
    font-style: italic;
    font-weight: bolder;
}

.powerLevelTextSectionLabel {
    font-size: 1.2em;
    font-weight: bolder;
    color: #FFFFFF;
    text-shadow: 2px 2px 3px #000000;
}

.powerLevelLinkText {
    margin-top:15px;
    font-size: .9em;
    cursor: pointer;
    color: #fad104;
    font-weight: bolder;
}
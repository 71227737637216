.App-body {
  background-color: #1e1e1e;
}

.bodyNoScroll {
  background-color: #1e1e1e;
  overflow: hidden;
}

.body {
  background-color: #1e1e1e;
  height: 100%;
  width: 100%;
}

.appWrapper {
  font-size: 1rem;
  font-family: 'Helvetica', sans-serif;
}
.deployDate {
    font-variant-caps: all-petite-caps;
    color: #49ecff;
    font-weight: bold;
}

.header {
    font-size: 20px;
    font-weight: bolder;
}

.subHeader {
    font-size: 18px;
    font-weight: bold;
}

.inlineHeader {
    font-weight: bold;
}

.jumpLink {
    cursor: pointer;
    color: #fad104;
    font-weight: bolder;
}